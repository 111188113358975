
.sandbox_context {
    white-space: pre-wrap;
  }
  
  .sandbox_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  
  .sandbox_block {
    background-color: white;
    padding: 20px;
  }
  
  .sandbox_chat_input {
    padding: 10px;
    outline: none;
    border: 1px solid lightgray;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }