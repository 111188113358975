.status {
    padding: 3px 8px;
    border: 1px solid lightgray;
    margin-left: 40px;
    cursor: pointer;
}

.status_order {
    background-color: #bbffb0;
}

.status_no_answer {
    background-color: #ffc9b1;
}