.chat_chats {
  height: calc(100vh - 40px - 80px);
  overflow-y: auto;
}

.chat_inputs {
  height: 80px;
}

.chat_chats::-webkit-scrollbar {
  width: 6px;
}

.chat_chats::-webkit-scrollbar-thumb {
  background-color: #888;
}

.chat_chats::-moz-scrollbar {
  width: 6px;
}

.chat_chats::-moz-scrollbar-thumb {
  background-color: #888;
}
