.good_name {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.good_title {
    cursor: pointer;
}

.good_title:hover {
    color: #2196F3;
}

.good_id {
    font-weight: 300;
    cursor: pointer;    
}

.good_id:hover {
    color: #2196F3;
}

.description {
    white-space: pre-wrap;
}