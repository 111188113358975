.chat_image {
    width: 50px;
}

.last_message {
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
    background-color: rgb(241, 241, 241);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}