.App {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: white; */
  height: calc(100vh - 40px);
}

.wrapper {
  max-width: 800px;
}

.good_info_count {
  font-weight: 700;
  font-size: 18px;
  margin-right: 5px;
}

.good_info_count-good {
  color: #2f89ff;
}

.good_info_count-bad {
  color: #dd0b0b;
}

.good_template_block {
  cursor: pointer;
  padding: 5px;
}

.good_template_block:hover {
  background-color: rgb(240, 240, 240);
}

.link {
  cursor: pointer;
}

.link:hover {
  color: var(--primary-color);
}

.test_result_block {
  background-color: whitesmoke;
  padding: 20px;
  margin-top: 20px;
}

.logo-block {
  padding: 30px 0;
  text-align: center;
}

.logo {
  width: 150px;
}
