.message {
    background-color: rgb(241, 241, 241);;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: fit-content;
}

.message_assistant {
    background-color: rgba(238, 238, 255, 1)
}
.message_user {
    background-color: white;
}

.message_role {
    margin-right: 5px;
}